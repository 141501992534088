<template>
    <div style="margin-left: 0;">
        <router-link :to="{ name: to, params: { type: type } }">
            <a-row height="56px">
                <a-col style="margin-right: 16px;">
                    <div class="favorite-card-image">
                        <img class="logo-img" :src="img" alt="4Space">
                        <span v-if="isFavorite" class="valign-middle" :style="{ fontSize: '1.25rem', zIndex: '1' }">
                            <heart-filled class="clickable-image" />

                        </span>
                    </div>
                </a-col>
                <a-col>
                    <p class="name-text">{{ type }}</p>
                    <p class="objects-text">{{ fuvObj?.count ? fuvObj?.count : fuvObj?.count === null ? 0 : ''
                    }} объекта </p>
                </a-col>
            </a-row>
        </router-link>
    </div>
</template>

<script>
import {
    HeartFilled,
} from "@ant-design/icons-vue";
export default {
    name: "FavoriteCard",

    props: {
        img: String,
        type: String,
        to: String,
        isFavorite: Boolean,
        fuvObj: Object,
    },

    // setup(props) {
    //     console.log(props.fuvObj);
    // },


    components: {

        HeartFilled,
    },

};
</script>


<style lang="less" scoped>
.clickable-image {
    opacity: 1;
}

.valign-middle {
    position: absolute;
    top: -5%;
    left: 60%;
    color: #FFF;
}

.favorite-card-image {
    width: 56px;
    height: 56px;
    border-radius: 12px;
    background: linear-gradient(86deg, #FFF -178.46%, #139EA3 92.5%);
    overflow: hidden;
    border: none;
}


.objects-text {
    color: rgba(26, 26, 35, 0.64);

    font-family: Aeroport;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.name-text {
    color: #1A1A23;
    margin-bottom: 4px;
    font-family: Aeroport;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.34px;
}
</style>
