<template>
  <section>
    <section class="favorites">
      <a-page-header @back="$router.push({ name: 'main' })" style="margin-bottom: 12px;" title="Избранные" />
      <div style="display: flex; justify-content: space-between; width: calc(100%);">
        <favorite-card isFavorite to="workplaces" img="/images/icons/work.svg" type="Рабочие места" :fuvObj="workPlace" />
        <favorite-card isFavorite to="meetingRooms" img="/images/icons/record.svg" type="Переговорные"
          :fuvObj="meetRoom" />
        <favorite-card isFavorite to="parkingLots" img="/images/icons/shuttle.svg" type="Парковки" :fuvObj="parking" />
        <favorite-card isFavorite to="lockers" img="/images/icons/lock.svg" type="Локеры" :fuvObj="loker" />
      </div>
    </section>
    <main>
      <a-row :gutter="54">
        <a-col style="padding-left: 13px; padding-right: 0;">
          <main v-if="loading">
            <a-spin />
          </main>
          <main v-else>

            <section class="ant-spin-container" v-if="favourites?.length > 0">
              <new-reservation-card v-for="(object) in favourites" :key="object?.id" :reservation="null" :object="object"
                allow-reserve is-slot />
            </section>
            <a-empty v-else>
              <template #description>
                Объектов в избранном нет
              </template>
            </a-empty>
          </main>
        </a-col>
        <!-- <a-col :span="8"> -->
        <!-- Здесь должен быть ваш фильтр -->
        <!-- </a-col> -->
      </a-row>
    </main>

  </section>
</template>

<script>
import FavoriteCard from "@/components/common/FavoriteCard";
import { onMounted } from "@vue/runtime-core";
import {computed, ref} from "@vue/reactivity";
import NewReservationCard from "@/components/admin/reservation/NewReservationCard";
import { requestAPI as objectAPI } from "@/compositions/objects"
import { requestAPI as reservationAPI } from "@/compositions/reservations";
import {useStore} from "vuex";
import {FAVORITES} from "../../../store/favorites";


export default {
  name: "UserFavouritesListPage",
  setup() {
    const store = useStore();
    const { fetchFavoriteObjectsByType } = reservationAPI();

    let workPlace = ref();
    let loker = ref();
    let parking = ref();
    let meetRoom = ref();

    const loading = ref(true);
    const data = ref([]);
    const { getFavouriteObjects } = objectAPI();

    const favourites = computed(() => store.getters[FAVORITES] );
   //store.state.cabinet.user
    onMounted(async () => {
      loading.value = true;
      if(!favourites.value.length) {
        const res = await getFavouriteObjects('');
        await store.dispatch(FAVORITES, res?.data);
      }
      loading.value = false;

      const fuvObj = await fetchFavoriteObjectsByType();

      workPlace.value = fuvObj.data.find(el => el.name === 'Рабочее место')
      loker.value = fuvObj.data.find(el => el.name === 'Локер')
      parking.value = fuvObj.data.find(el => el.name === 'Парковка')
      meetRoom.value = fuvObj.data.find(el => el.name === 'Переговорная')

    });

    return {
      workPlace,
      loker,
      parking,
      meetRoom,
      loading,
      data,
      favourites
    };
  },
  components: { NewReservationCard, FavoriteCard }
}
</script>

<style lang="less" scoped>
.ant-spin-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
</style>
